import React from 'react';
import { Box, Typography, Card, CardContent, Container } from '@mui/material';

function PhraseSection({ service }) {


  return (
    <Box sx={{ bgcolor: '#E3E3E3', py: { xs: 4, sm: 8 }, borderColor: 'divider' }}>
      <Container maxWidth="xl">
        <Box sx={{ my: 2, borderTop: 1, borderBottom: 1 }}>
          <Typography variant="h2" align="center" gutterBottom sx={{ fontWeight: 'light', mb: 4, mt: 4, fontSize: { xs: '2.5rem', sm: '5rem' }, fontStyle: 'oblique' }}>
            {service.tituloFrase}
          </Typography>
          <Card elevation={0} sx={{ mt: 4, textAlign: 'center', bgcolor: '#E3E3E3' }}>
            <CardContent>
              <Typography align="center" sx={{ mb: 3, fontSize: { xs: '1.2rem', sm: '1.6rem' } }}>
                {service.frasePage}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

export default PhraseSection;


