import React from 'react';
import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';


// Importamos los componentes
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import HomePage from './screens/HomePage/HomePage';
import BlogPage from './screens/BlogPage/BlogPage';
import ContactanosPage from './screens/ContactanosPage/ContactanosPage';
import ProyectosPage from './screens/ProyectosPage/ProyectosPage';
import ServiciosPage from './screens/ServiciosPage/ServiciosPage';




const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", "Roboto", sans-serif',
  },
});

const AppRoutes = () => {
  return (
    <Routes>
      {/* Solo queda una ruta que es HomePage */}
      <Route path="/" element={<HomePage />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/contacto" element={<ContactanosPage />} />
      <Route path="/proyectos" element={<ProyectosPage />} />
     
      <Route path="/servicios/:serviceName" element={<ServiciosPage />} />


      {/* Redirige cualquier otra ruta no definida a HomePage */}
      <Route path="*" element={<HomePage />} />
    </Routes>
  );
};

const Layout = () => {
  // Ya no hay rutas en las que no queremos mostrar la Navbar
  // La Navbar se muestra siempre, por lo que eliminamos la lógica condicional

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <AppRoutes />
      </Box>
      <Footer />
    </Box>
  );
}

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout />
      </Router>
    </ThemeProvider>
  );
};

export default App;



