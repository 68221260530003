import React from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { Box, Typography, useTheme, useMediaQuery, Button } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const testimonials = [
  {
    id: 1,
    text: "Risk and Design Consulting encargado de realizar el análisis de vulnerabilidad y diseño de repotenciación del claustro de San Ignacio",
    author: "Juan Pérez",
    position: "CEO de Empresa X"
  },
  {
    id: 2,
    text: "Nos unimos temporalmente con O.M.Ramirez y Asociados para diseñar el Hospital del Niño en Ciudad de Panamá.",
    author: "Ana Gómez",
    position: "Gerente de Proyectos en Compañía Y"
  },
  {
    id: 3,
    text: "Risk and Design Consulting encargado de realizar el análisis de vulnerabilidad y diseño de repotenciación de la biblioteca de la Univrsidad CES.",
    author: "Carlos López",
    position: "Director de Innovación en Z Corp"
  }
];

function TestimonialSection() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true
  };

  const handleContactClick = () => {
    navigate('/contacto');
  };

  return (
    <Box sx={{ bgcolor: '#2C3336', py: 8 }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        color="common.white"
        sx={{ fontFamily: 'Montserrat', fontWeight: 700, mb: 3 }}
      >
        Noticias Recientes
      </Typography>
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <Box key={testimonial.id} sx={{ px: isMobile ? 2 : 8, overflow: 'hidden' }}>
          <Typography
            variant="h6"
            align="center"
            color="common.white"
            sx={{
              fontStyle: 'italic',
              fontSize: isMobile ? '1rem' : '1.5rem', 
              wordWrap: 'break-word',
              maxWidth: isMobile ? '80%' : 'none', // Limita el ancho máximo en móviles
              mx: 'auto', // Centra el texto cuando el maxWidth es menor al 100%
              padding: isMobile ? '0 10px' : '0', // Agrega padding en móviles para que el contenido no toque los bordes
              whiteSpace: 'pre-wrap',
            }}
          >
            “{testimonial.text}”
          </Typography>
          {/* <Typography
            variant="subtitle1"
            align="center"
            color="common.white"
            sx={{
              mt: 2,
              fontFamily: 'Montserrat',
              fontWeight: 600,
              fontSize: isMobile ? '0.875rem' : '1.25rem',
              display: isMobile ? 'none' : 'block'
            }}
          >
            — {testimonial.author}, {testimonial.position}
          </Typography> */}
        </Box>
        ))}
      </Slider>
      <Box textAlign="center" mt={4}>
      <Button
        variant="contained"
        size="large"
        sx={{
          marginTop:'12px',
          fontFamily: 'Montserrat',
          fontWeight: 700,
          backgroundColor: '#961A1D', // Tu color personalizado
          '&:hover': {
            backgroundColor: '#0056b3', // El color que deseas al hacer hover, he puesto un azul como ejemplo
          },
        }}
        onClick={handleContactClick}
      >
        Contáctanos
      </Button>
    </Box>
    </Box>
  );
}

export default TestimonialSection;