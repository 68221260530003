// src/data/imageData.js
import computacion from '../../../assets/computacion1.png';
import estructuras from '../../../assets/estructuras1.png';
import geociencias from '../../../assets/geociencias1.png';

export const imageTextMap = [
  {
    image: computacion,
    area: "Computación",
    description: "Herramientas computacionales para una nueva Ingeniería",
    buttonColor: '#4C8BF5',
    path: '/servicios/computacion' 
  },
  {
    image: estructuras,
    area: "Ingeniería Estructural",
    description: "Seguridad estructural y financiera",
    buttonColor: '#F5A623', // Naranja para Estructuras
    path: '/servicios/disenoEstructural'
  },
  {
    image: geociencias,
    area: "Geociencias",
    description: "Minimizamos la incertidumbre",
    buttonColor: '#34A853', // Verde para Geociencias
    path: '/servicios/geociencias'
  },
];
