import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Link, Typography, IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Logo from '../../assets/logo.png'; // Asegúrate de cambiar a la ruta del logo de tu empresa

// Estilos personalizados para los Links
const linkStyle = {
  textDecoration: 'none',
  color: 'white',
  '&:hover': {
    textDecoration: 'underline',
    textDecorationThickness: '2px',
    textDecorationColor: '#961A1D', // Puedes cambiar este color por el que desees
    transition: 'text-decoration-color 0.3s',
  },
};

// Estilos para los botones de icono con efecto hover
const iconButtonStyle = {
  color: 'white',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.2)',
    color: '#961A1D', // Cambia este color por el que desees
  },
};

const Footer = () => {
  const year = new Date().getFullYear();
  const navigate = useNavigate();


  return (
    <Box component="footer" sx={{ bgcolor: '#2C3336', color: 'white', py: 6 }}>
      <Container maxWidth="xl">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={3}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
            <IconButton edge="start" color="inherit" aria-label="logo" onClick={() => navigate('/')}>
              <img src={Logo} alt="Logo" height="50"/>
              </IconButton>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                © {year} R&DC
              </Typography>
              <Typography variant="caption">
                Todos los derechos reservados.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" align="center" gutterBottom>
              Explorar
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Link href="/servicios" sx={linkStyle}>Servicios</Link>
              <Link href="/blog" sx={linkStyle}>Blog</Link>
              <Link href="/contacto" sx={linkStyle}>Contactanos</Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" align="center" gutterBottom>
              Legal
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Link href="#" sx={linkStyle}>Términos de Uso</Link>
              <Link href="#" sx={linkStyle}>Política de Privacidad</Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" align="center" gutterBottom>
              Síguenos
            </Typography>
            <Box display="flex" justifyContent="center">
              <IconButton href="https://www.instagram.com/riskanddesignconsulting/" sx={iconButtonStyle}>
                <InstagramIcon />
              </IconButton>
              <IconButton href="https://twitter.com/RiskandDesign" sx={iconButtonStyle}>
                <TwitterIcon />
              </IconButton>
              <IconButton href="https://www.linkedin.com/company/risk-and-design-consulting/" sx={iconButtonStyle}>
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" align="center" gutterBottom>
              Contáctanos
            </Typography>
            <Typography variant="body2" align="center" display="block">
              info@r&dc.com
            </Typography>
            <Typography variant="body2" align="center" display="block">
              +(57)-318-335-1733
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;

