import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Toolbar, IconButton, Typography, Container, useMediaQuery, Drawer, List, ListItem, ListItemText, Divider, Button, Menu, MenuItem,} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Fade from '@mui/material/Fade';

import Logo from '../../assets/logo.png';

const pages = [
  { name: 'Inicio', route: '/' },
  { name: 'Servicios', route: '/servicios' },
  // { name: 'Proyectos', route: '/proyectos' },
  { name: 'Blog', route: '/blog' },
  { name: 'Contactanos', route: '/contacto' },
];

const servicePages = [
  { name: 'Estructuras', route: '/servicios/disenoEstructural' },
  { name: 'Geociencias', route: '/servicios/geociencias' },
  { name: 'Computacion', route: '/servicios/computacion' },
];

const NavButton = ({ page, onMouseEnter, onMouseLeave }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (page.name === 'Servicios') {
      setAnchorEl(event.currentTarget);
    } else {
      navigate(page.route);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (route) => {
    navigate(route);
    handleClose();
  };

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Button
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          mx: 2,
          color: 'white',
          padding: 1,
          "&:hover": {
            backgroundColor: '#961A1D',
          },
          textDecoration: 'none'
        }}
      >
        <Typography sx={{ fontFamily: 'Montserrat, sans-serif' }}>
          {page.name}
        </Typography>
        {page.name === 'Servicios' && <ArrowDropDownIcon sx={{ color: 'white' }} />}
      </Button>
      {page.name === 'Servicios' && (
        <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        TransitionComponent={Fade} // Usa el componente Fade para animar la entrada
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#2C3336',
            color: 'white',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)', // Agrega una sombra para un aspecto más profundo
            borderRadius: '4px', // Bordes redondeados para el menú desplegable
            marginTop: '8px', // Espacio entre el botón y el menú desplegable
            '& .MuiMenuItem-root': {
              '&:hover': {
                backgroundColor: '#961A1D', // Cambia el color de fondo al pasar el cursor
              },
              '&:active': {
                backgroundColor: '#961A1D', // Cambia el color de fondo al hacer clic
              }
            }
          },
        }}
      >
          {servicePages.map((servicePage) => (
            <MenuItem key={servicePage.name} onClick={() => handleMenuItemClick(servicePage.route)}>
              {servicePage.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleListItemClick = (route) => {
    navigate(route);
    setMobileOpen(false);
  };

  const drawerWidth = 240;

  const drawer = (
    <Box
      sx={{       
        width: drawerWidth,
        textAlign: 'center',
        fontFamily: 'Montserrat, sans-serif',
        height: '100%',
        backgroundColor: 'rgba(44, 51, 54, 0.9)',
        color: 'white'
      }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        R&DC
      </Typography>
      <Divider sx={{ bgcolor: '#961A1D', height: '2px', width: '80%', mx: 'auto' }} />
      <List>
        {pages.map((page) => (
          <ListItem button key={page.name} onClick={() => handleListItemClick(page.route)}>
            <ListItemText primary={page.name} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="static" sx={{ background: '#2C3336', boxShadow: 'none', color: 'white', py: 1 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ minHeight: '64px', justifyContent: 'space-between', alignItems: 'center' }}>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2, display: { md: 'none' } }} onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <IconButton edge="start" color="inherit" aria-label="logo" onClick={() => navigate('/')}>
            <img src={Logo} alt="Logo" style={{ maxHeight: '100%', maxWidth: '325px' }} />
          </IconButton>

          {!isMobile ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {pages.map((page) => (
                <NavButton key={page.name} page={page} onMouseEnter={handleDrawerToggle} onMouseLeave={handleDrawerToggle} />
              ))}
            </Box>
          ) : (
            <Drawer
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;


