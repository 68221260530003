import estructuras11 from '../../assets/images/imagen3.jpg';
import computacion11 from '../../assets/images/computacion11.jpg';
import geociencias12 from '../../assets/geociencias2.png';

export const blogPosts = [
  {
    title: "Tendencias en Diseño Estructural",
    description: "Exploramos las innovaciones actuales en el diseño estructural",
    content: "El diseño estructural es una disciplina en constante evolución, impulsada por la innovación en materiales, métodos de construcción y software de diseño. En este blog, discutimos las últimas tendencias, incluyendo el uso de materiales sostenibles, técnicas de modelado por computadora, y enfoques innovadores en la ingeniería sísmica. Exploramos cómo estos avances no solo mejoran la eficiencia y la seguridad, sino que también abren nuevas posibilidades en el diseño arquitectónico.",
    image: estructuras11,
    category: "Diseño Estructural",
    path: '/servicios/disenoEstructural',
    service: 'Estructuras'
  },
  {
    title: "Avances en Computación para Ingeniería Civil",
    description: "El campo de la computación ha revolucionado la forma en que se desarrollan los proyectos de ingeniería civil",
    content: "La computación en la ingeniería civil ha traído cambios significativos en los últimos años. Tecnologías como la inteligencia artificial, el aprendizaje automático y la realidad virtual están cambiando la forma en que los ingenieros abordan los problemas y diseñan soluciones. Este blog se sumerge en ejemplos concretos de estas tecnologías en acción, destacando su impacto en la eficiencia, precisión y posibilidades creativas en la ingeniería civil.",
    image: computacion11,
    category: "Computación",
    path: '/servicios/computacion',
    service: 'Computacion'
  },
  {
    title: "Geociencias y su Impacto en Proyectos de Ingeniería",
    description: "Las geociencias juegan un papel crucial en la ingeniería civil",
    content: "En este blog, exploramos el papel de las geociencias en la ingeniería civil. Discutimos cómo el análisis del suelo, la evaluación de riesgos geológicos y la gestión de recursos hídricos son fundamentales para la planificación y ejecución de cualquier proyecto de ingeniería. Profundizamos en estudios de caso que ilustran la aplicación de las geociencias en proyectos reales, resaltando su importancia en la prevención de desastres y en la sostenibilidad ambiental.",
    image: geociencias12,
    category: "Geociencias",
    path: '/servicios/geociencias',
    service: 'geociencias'
  }
  // Puedes añadir más posts aquí
];