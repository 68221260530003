import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Container, Grid, Card, CardMedia, Button, useTheme, useMediaQuery } from '@mui/material';

// La información de las imágenes y descripciones ahora vendrá como props

function MainSection({ data }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contacto');
  };

  // Los datos, como el título, descripción e imagen ahora son dinámicos
  return (
    <Box sx={{ bgcolor: '#E3E3E3', py: 8 }}>
      <Container maxWidth="xl">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant={isMobile ? "h3" : "h2"}
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#961A1D',
              }}
            >
              {data.titulo} {/* Usando el título dinámico */}
            </Typography>
            <Typography sx={{ fontSize: '1.5rem'}} paragraph>
              {data.descripcion} {/* Usando la descripción dinámica */}
            </Typography>
            <Button variant="contained"  sx={{ mt: 2, backgroundColor: '#961A1D' }} onClick={handleContactClick}>
              Solicita una Consulta
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardMedia
                component="img"
                height="100%"
                image={data.imagen} // Usando la imagen dinámica
                alt={data.titulo} // Usando el título como texto alternativo
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default MainSection;


