import React from 'react';


import MainSection from './Sections/MainSection';
import AboutUsSection from './Sections/AboutUsSection';
import TestimonialSection from './Sections/TestimonialSection';
import ServicesSection from './Sections/ServicesSection';
import ClientsSection from './Sections/ClientsSection';
import ContactanosSection from './Sections/ContactanosSection';

function HomePage() {
  // Estilos para prevenir el desbordamiento lateral
  const pageStyles = {
    overflowX: 'hidden', // Esto previene el desbordamiento horizontal
    maxWidth: '100vw', // Esto limita el ancho máximo de la página al ancho de la ventana gráfica
  };


  return (
    <div style={pageStyles}>
      <MainSection />
      <AboutUsSection />
      <TestimonialSection />
      <ServicesSection />
      <ClientsSection />
      <ContactanosSection />
    </div>
  );
}

export default HomePage;





