import React, { useState } from 'react';
import { Box, Typography, Grid, TextField, Button, Container } from '@mui/material';
import MensajeExito from '../MensajeExito';

function ContactanosSection() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.firstName) tempErrors.firstName = 'El nombre es obligatorio';
    if (!formData.lastName) tempErrors.lastName = 'Los apellidos son obligatorios';
    if (!formData.email) {
      tempErrors.email = 'El correo electrónico es obligatorio';
    } else if (!validateEmail(formData.email)) {
      tempErrors.email = 'Correo electrónico no válido';
    }
    if (!formData.message) tempErrors.message = 'El mensaje es obligatorio';
    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setFormSubmitted(true);
      // Aquí podrías implementar la lógica de envío del formulario
    }
  };

  if (formSubmitted) {
    return <MensajeExito />;
  }

  return (
    <Box sx={{ bgcolor: '#E3E3E3', py: 8 }}>
      <Container maxWidth="md">
        <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#961A1D', mb: 4 }}>
          Construye el Futuro con Nosotros
        </Typography>
        <Typography variant="h6" align="center" sx={{ mb: 4 }}>
          Deja tus datos y nos pondremos en contacto para llevar a cabo tus proyectos más ambiciosos.
        </Typography>
        <form onSubmit={handleSubmit}> {/* Encierra los campos en un elemento form */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="firstName"
                name="firstName"
                label="Nombre"
                fullWidth
                autoComplete="given-name"
                variant="outlined"
                value={formData.firstName}
                onChange={handleChange}
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="lastName"
                name="lastName"
                label="Apellidos"
                fullWidth
                autoComplete="family-name"
                variant="outlined"
                value={formData.lastName}
                onChange={handleChange}
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="email"
                name="email"
                label="Correo electrónico"
                fullWidth
                autoComplete="email"
                variant="outlined"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="message"
                name="message"
                label="Mensaje"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={formData.message}
                onChange={handleChange}
                error={!!errors.message}
                helperText={errors.message}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{
              marginTop: '12px',
              fontFamily: 'Montserrat',
              fontWeight: 700,
              backgroundColor: '#961A1D',
              '&:hover': {
                backgroundColor: '#0056b3',
              },
              width: 1,
              borderRadius: '18px'
            }}
          >
            Contáctanos
          </Button>
        </form>
      </Container>
    </Box>
  );
}

export default ContactanosSection;


