import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Grid, Card, CardContent, Container } from '@mui/material';

function DetailsSection({ service }) {
  const [selectedProduct, setSelectedProduct] = useState(service.productos[0]);
  const navigate = useNavigate();

  const handleButtonClick = (producto) => {
    setSelectedProduct(producto);
  };

  const handleContactClick = () => {
    navigate('/contacto');
  };

  return (
    <Box sx={{ bgcolor: 'white', py: { xs: 4, sm: 8 }, borderColor: 'divider' }}>
      <Container maxWidth="xl">
        <Box sx={{ my: 2, borderTop: 1, borderBottom: 1 }}>
          <Typography variant="h2" align="center" gutterBottom sx={{ fontWeight: 'bold', mb: 4, mt: 4, fontSize: { xs: '2.5rem', sm: '5rem' } }}>
            {service.ventajaCompetitiva}
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {service.productos.map((producto, index) => (
              <Grid item key={index} xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  onClick={() => handleButtonClick(producto)}
                  sx={{ 
                    borderRadius: '50px', 
                    m: 1,
                    width: '100%', // asegura que el botón se extienda en pantallas pequeñas
                    fontSize: '1rem',
                    color: selectedProduct.id === producto.id ? 'white' : '#961A1D',
                    backgroundColor: selectedProduct.id === producto.id ? '#961A1D' : 'transparent',
                    border: '1px solid',
                    borderColor: selectedProduct.id === producto.id ? '#961A1D' : '#ccc',
                    '&:hover': {
                      backgroundColor: '#961A1D',
                      color: 'white',
                      transform: 'scale(1.05)',
                    },
                    height: '50px',
                    py: 2, // padding vertical para botones más grandes
                  }}
                >
                  {producto.nombre}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Card elevation={0} sx={{ mt: 4, textAlign: 'center' }}>
            <CardContent>
              <Typography variant="h5" align="center" sx={{ fontWeight: 'bold', mb: 3, textDecoration: 'underline', textDecorationColor: '#961A1D', fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
                {selectedProduct.nombre}
              </Typography>
              <Typography align="center" sx={{ mb: 3, fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                {selectedProduct.descripcion}
              </Typography>
              {/* Botón de llamada a la acción para contactar */}
              <Button variant="contained"  sx={{ mt: 4, backgroundColor: '#961A1D', borderRadius: '12px', fontSize: '1.15rem', '&:hover': {backgroundColor: '#7a0c12'} }} onClick={handleContactClick}>
                Contáctanos
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

export default DetailsSection;


