import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Paper, Typography, Button, Box} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { imageTextMap } from '../utils/imageData';


function MainSection() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleServiceClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageTextMap.length);
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  const currentImageText = imageTextMap[currentImageIndex];

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? imageTextMap.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageTextMap.length);
  };

  return (
    <Box sx={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <Paper
        square
        elevation={0}
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `url(${currentImageText.image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(44, 51, 54, 0.7)',
            zIndex: 1,
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            zIndex: 2,
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            textAlign: 'center',
            width: '100%',
          }}
        >
          {/* <Container maxWidth="xl" sx={{ marginX: 'auto', overflow: 'hidden', py: 8 }}> 
          <Typography sx={{ marginBottom: '3rem', fontSize: '4rem', fontFamily: 'Montserrate'}}>Seguridad estructural y financiera</Typography>
          </Container> */}
          <Typography variant={isMobile ? 'h3' : 'h1'} component="h1" gutterBottom sx={{ fontFamily: 'Roboto' }}>
            {currentImageText.area}
          </Typography>
          <Typography variant={isMobile ? 'h5' : 'h3'} component="h2" gutterBottom sx={{ opacity: 0.8, fontFamily: 'Montserrat' }}>
            {currentImageText.description}
          </Typography>
          <Button variant="contained" size="large" sx={{ backgroundColor: currentImageText.buttonColor, mt: 2 }} onClick={() => handleServiceClick(currentImageText.path)}>
            Conoce más
          </Button>
          
        </Box>
        <Button
          sx={{
            position: 'absolute',
            top: '50%',
            left: 16,
            transform: 'translateY(-50%)',
            zIndex: 3,
            color: 'white',
          }}
          onClick={handlePrev}
        >
          <ArrowBackIosNewIcon />
        </Button>
        <Button
          sx={{
            position: 'absolute',
            top: '50%',
            right: 16,
            transform: 'translateY(-50%)',
            zIndex: 3,
            color: 'white',
          }}
          onClick={handleNext}
        >
          <ArrowForwardIosIcon />
        </Button>
      </Paper>
    </Box>
  );
}

export default MainSection;

