import React from 'react';
import { useParams } from 'react-router-dom';
import { serviciosInfo } from './serviciosInfo';

//Importamos las secciones
import MainSection from './MainSection';
import AdvantagesSection from './AdvantagesSection';
import DetailsSection from './DetailsSection';
import PhraseSection from './PhraseSection';

function ServiciosPage() {
  let { serviceName } = useParams(); // Esto obtendrá el nombre del servicio de la URL
  let serviceData = serviciosInfo[serviceName]; // Obtiene la información correspondiente

  if (!serviceData) {
    // Manejar el caso en que no se encuentre el servicio (redirigir o mostrar un mensaje de error)
    return <div>El servicio solicitado no existe</div>;
  }

  const pageStyles = {
    overflowX: 'hidden', // Esto previene el desbordamiento horizontal
    maxWidth: '100vw', // Esto limita el ancho máximo de la página al ancho de la ventana gráfica
  };

  return (
    <div style={pageStyles}>
      <MainSection data={serviceData} />
      <PhraseSection service={serviceData} />
      <AdvantagesSection data={serviceData} />
      <DetailsSection service={serviceData} />
      {/* <ContactanosSection service={serviceData} /> */}
    </div>
  );
}

export default ServiciosPage;


