import React from 'react';
import { Box, Typography, useTheme, useMediaQuery, Container } from '@mui/material';
import Slider from 'react-slick';

// Importar los estilos de slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Importar los logos de los clientes
import cliente1 from '../../../assets/aliados/arconsa.png';
import cliente2 from '../../../assets/aliados/argos.png';
import cliente3 from '../../../assets/aliados/sura.png';
import cliente4 from '../../../assets/aliados/arconsa.png';
import cliente5 from '../../../assets/aliados/argos.png';
import cliente6 from '../../../assets/aliados/sura.png';
import cliente7 from '../../../assets/aliados/arconsa.png';
import cliente8 from '../../../assets/aliados/sura.png';

const logos = [cliente1, cliente2, cliente3, cliente4, cliente5, cliente6, cliente7, cliente8];

function ClientsSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Configuración para react-slick
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };


  return (
    <Box sx={{ py: 8, bgcolor: 'white' }}>
      <Container maxWidth="xl">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h4" align="center" gutterBottom>
            Nuestros Clientes
          </Typography>
        </Box>
        <Slider {...settings}>
          {logos.map((logo, index) => (
            <Box
              key={index}
              sx={{
                marginTop: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '150px', 
                padding: theme.spacing(3), 
                marginLeft: { xs: '3.5rem', md: '6rem' }, 
                '&:last-child': {
                  marginRight: '0'
                }
              }}
            >
              <img
                src={logo}
                style={{
                  maxHeight: '100px', 
                  maxWidth: '100%', 
                  objectFit: 'contain',
                }}
                alt={`Logo del cliente ${index + 1}`}
              />
            </Box>
          ))}
        </Slider>
      </Container>
    </Box>
  );
}

export default ClientsSection;




