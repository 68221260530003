import React from 'react';
import { Container, Box, Typography, Grow, Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function MensajeExito() {
  return (
    <Box sx={{ bgcolor: '#E3E3E3', py: 8 }}>
      <Container maxWidth="md">
        <Grid container justifyContent="center" alignItems="center" direction="column">
          <Grid item>
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
              <CheckCircleOutlineIcon sx={{ fontSize: 60, color: '#4caf50', mb: 2 }} />
            </Grow>
          </Grid>
          <Grid item>
            <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#961A1D', mb: 4 }}>
              ¡Gracias por contactarnos!
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center" sx={{ mb: 4 }}>
              Hemos recibido tu mensaje. Uno de nuestros representantes se pondrá en contacto contigo pronto.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default MensajeExito;

