import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Button, Grow } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function SuccessMessage() {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/');
  };

  return (
    <Container component="main" maxWidth="md" sx={{ mt: 8, mb: 8 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 4,
          boxShadow: 0,
          borderRadius: 2,
          backgroundColor: 'white',
          mt: 4,
          position: 'relative',
          zIndex: 1
        }}
      >
        <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
          <CheckCircleOutlineIcon sx={{ fontSize: 60, color: '#4caf50', mb: 2 }} />
        </Grow>
        <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#961A1D', mb: 4 }}>
          ¡Gracias por contactarnos!
        </Typography>
        <Typography variant="h6" align="center" sx={{ mb: 4 }}>
          Hemos recibido tu mensaje. Uno de nuestros representantes se pondrá en contacto contigo pronto.
        </Typography>
        <Button variant="contained" sx={{ mt: 1, backgroundColor: '#961A1D', textTransform: 'bold', borderRadius: '18px' }} onClick={handleContactClick}>
          Volver a la página principal
        </Button>
      </Box>
    </Container>
  );
}

export default SuccessMessage;
