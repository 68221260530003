import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Card, CardContent, Grid, Container, useTheme } from '@mui/material';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

// Imagenes del Carrusel
import estructuras from '../../../assets/images/estructuras12.jpg';
import imagen_innovacion from '../../../assets/images/imagen7.jpg';
import imagen_sostenibilidad from '../../../assets/images/imagen4.jpg';
import imagen_calidad from '../../../assets/images/imagen1.jpg';

function AboutUsSection() {
  const theme = useTheme();

  // Opciones para el Slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // Función para generar las tarjetas de diferenciación
  const renderDifferenceCard = (title, description) => (
    <Grid item xs={12} sm={4}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
          ':hover': {
            transform: 'scale(1.05)',
            boxShadow: theme.shadows[10],
          },
          borderLeft: 5,
          borderColor: '#961A1D',
          backgroundColor: theme.palette.grey[100],
          transition: 'transform .3s ease-in-out, box-shadow .3s ease-in-out',
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <Container maxWidth="xl" sx={{ marginX: 'auto', overflow: 'hidden', py: 8 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Slider {...settings}>
            <Box
              component="img"
              sx={{
                width: '100%',
                height: 'auto',
                boxShadow: 1,
                borderRadius: 2
              }}
              src={estructuras}
              alt="Estructuras"
            />
            <Box
              component="img"
              sx={{
                width: '100%',
                height: 'auto',
                boxShadow: 1,
                borderRadius: 2
              }}
              src={imagen_innovacion}
              alt="Innovación"
            />
            <Box
              component="img"
              sx={{
                width: '100%',
                height: 'auto',
                boxShadow: 1,
                borderRadius: 2
              }}
              src={imagen_sostenibilidad}
              alt="Sostenibilidad"
            />
            <Box
              component="img"
              sx={{
                width: '100%',
                height: 'auto',
                boxShadow: 1,
                borderRadius: 2
              }}
              src={imagen_calidad}
              alt="Calidad"
            />
          </Slider>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
          <Typography
             variant="h4"
             gutterBottom
             sx={{
                fontFamily: 'Roboto',
                textDecoration: 'underline', // Añade un subrayado al título
                textDecorationColor: '#961A1D', // Cambia el color del subrayado
                textDecorationThickness: '2px', // Ajusta el grosor del subrayado
                textUnderlineOffset: '3px', // Puede ajustar la distancia del subrayado desde el texto
                }}
           >
              Sobre Nosotros
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" paragraph sx={{ fontFamily: 'Montserrat'}}>
            Risk and Design Consulting aporta soluciones innovadoras y especializadas en Ingeniería Sísmica
            para proyectos de infraestructura y vivienda. Fundada por un equipo con amplia experiencia en los sectores industriales y academicos, nuestra empresa se centra en abordar retos complejos
            con un enfoque integral. Desde nuestro Depatamento de Innovación hemos desarrollado herramientas computacionales avanzadas, que nos permiten alcanzar soluciones
            efectivas y de máximo valor para nuestros clientes. A partir de nuestros tres Departamentos, logramos garantizar seguridad estructural y financiera en cada proyecto.
            </Typography>
          </Box>
        </Grid>
        {/* Aquí irían las tarjetas que explican por qué somos diferentes */}
        {renderDifferenceCard(
          "Innovación Constante",
          "En Risk and Design Consulting estamos comprometidos con elevar el nivel de la Ingeniería por eso la innovación está en el núcleo de nuestro enfoque. Afrontamos los desafíos de la Ingeniería con soluciones avanzadas y creativas, asegurando a nuestros clientes resultados excepcionales y adaptados a un mundo en constante cambio."
        )}
        {renderDifferenceCard(
          "Diversidad de Soluciones",
          "Valoramos la diversidad de enfoques para enfrentar cada desafío. Entendemos que cada proyecto es único, y por ello, exploramos múltiples soluciones para encontrar la más adecuada, asegurando el máximo valor para nuestros clientes."
        )}
        {renderDifferenceCard(
          "Enfoque Integral",
          "Abordamos cada proyecto de manera integral, tratando el suelo, la subestructura y la superestructura como partes de un único desafío. Esta perspectiva holística, combinada con nuestro entendimiento del proceso constructivo, asegura soluciones eficientes, reflejando nuestro compromiso con la excelencia en cada fase del proyecto."
        )}
      </Grid>
    </Container>
  );
}

export default AboutUsSection;




