import React, { useState } from 'react';
import { Typography, Grid, Container, Tabs, Tab, Paper, Box, Button } from '@mui/material';
import { blogPosts } from './blogPosts';
import { useNavigate } from 'react-router-dom';

function BlogPage() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('Diseño Estructural');

  const handleServiceClick = (path) => {
    navigate(path);
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ bgcolor: 'white', py: 8 }}>
        <Typography variant="h2" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#961A1D', marginBottom: '40px' }}>
          R&DC Blog
        </Typography>

        <Tabs 
          value={selectedTab} 
          onChange={handleChangeTab} 
          centered 
          sx={{ 
            marginBottom: '40px', 
            '.MuiTabs-indicator': { backgroundColor: '#961A1D' }, 
            '.MuiTab-root': { color: 'black' },
            '.Mui-selected': { color: 'black' } // Cambia el color del texto del tab seleccionado a rojo
          }}
        >
          <Tab label="Diseño Estructural" value="Diseño Estructural" />
          <Tab label="Computación" value="Computación" />
          <Tab label="Geociencias" value="Geociencias" />
        </Tabs>

        <Grid container spacing={4}>
          {blogPosts.filter(post => post.category === selectedTab).map((post, index) => (
            <Grid item xs={12} key={index}>
              <Paper elevation={0} sx={{ display: 'flex', marginBottom: '24px', overflow: 'hidden' }}>
                <img src={post.image} alt={post.title} style={{borderRadius: '10px', width: 500, height: 500, display: { xs: 'none', sm: 'block' } }} />
                <Box sx={{ padding: 6, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography gutterBottom variant="h3" component="div" align="center">
                    {post.title}
                  </Typography>
                  <Typography gutterBottom component="div" align="center" sx={{ color: '#961A1D', fontWeight: 'bold', fontSize: '25px'}}>
                    {post.description}
                  </Typography>
                  <Typography variant="h5" color="text.secondary" align="center">
                    {post.content}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Button
                      onClick={() => handleServiceClick(post.path)}
                      variant="contained"
                      color="primary"
                      size="large"
                      sx={{
                        textTransform: 'capitalize',
                        fontSize: '18px',
                        borderRadius: '20px',
                        marginTop:'12px',
                        fontFamily: 'Montserrat',
                        fontWeight: 700,
                        backgroundColor: '#961A1D', // Tu color personalizado
                        '&:hover': {
                          backgroundColor: '#0056b3', // El color que deseas al hacer hover, he puesto un azul como ejemplo
                        },
                      }}
                    >
                      Explora nuestros servicios en {post.service}
                    </Button>
                  </Box>
                </Box>
                
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default BlogPage;



