import React from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Container, Grid, styled } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Slider from 'react-slick'; // Importar Slider
import "slick-carousel/slick/slick.css"; // Importar estilos slick
import "slick-carousel/slick/slick-theme.css"; // Importar estilos slick-theme

// Creación de un componente de lista personalizado para las viñetas
const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '30px',
  color: theme.palette.success.main,
}));

// Componente de viñeta personalizada
const CustomCheckCircleIcon = styled(CheckCircleIcon)({
  fontSize: '1.6rem', // Tamaño más grande para el icono
});

function AdvantagesSection({ data }) {
  // Configuraciones para el slider
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Cambia a la cantidad de tiempo deseada
  };

  return (
    <Box sx={{ bgcolor: 'white', py: 8 }}>
      <Container maxWidth="xl">
        <Grid container spacing={6} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6}>
            {/* Slider envolvente */}
            <Slider {...settings}>
              {data.imagesAdvantages.map((image, index) => (
                <Box
                  key={index}
                  component="img"
                  src={image}
                  alt={`Ventaja ${index}`}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '600px', // Mantenemos el tamaño más grande para la imagen
                    objectFit: 'cover',
                    borderRadius: '8px',
                  }}
                />
              ))}
            </Slider>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ pl: { md: 4 } }}>
              <Typography variant="h5" paragraph sx={{ mb: 4, textTransform: 'uppercase' }}>
                {data.quote} {/* Frase corta adicional */}
              </Typography>
              <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', color: '#961A1D', mb: 2 }}>
                {data.descripcionCorta}
              </Typography>
              <List sx={{ color: 'black', mb: 4, fontWeight: 'bold' }}>
                {data.puntosClave.map((punto, index) => (
                  <ListItem key={index} disableGutters sx={{ pl: 0, my: 1, fontWeight: 'bold' }}>
                    <CustomListItemIcon>
                      <CustomCheckCircleIcon />
                    </CustomListItemIcon>
                    <ListItemText primary={punto} sx={{ typography: 'h6' }} /> 
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default AdvantagesSection;


