import estructuras11 from '../../assets/images/imagen3.jpg';
import estructuras12 from '../../assets/images/estructuras13.jpg';
import estructuras13 from '../../assets/images/estructuras14.jpg';
import estructuras14 from '../../assets/images/imagen6.jpg';
import estructuras15 from '../../assets/images/estructuras11.jpg';


import computacion11 from '../../assets/images/computacion11.jpg';
import computacion12 from '../../assets/images/computacion13.jpg';
import computacion13 from '../../assets/computacion3.png';
import computacion14 from '../../assets/images/computacion15.jpg';

import geociencias11 from '../../assets/images/geociencias11.jpg';
import geociencias12 from '../../assets/geociencias2.png';
import geociencias13 from '../../assets/images/geociencias12.jpg';
import geociencias14 from '../../assets/service4.png';

export const serviciosInfo = {

  disenoEstructural: {
        titulo: "Ingeniería Estructural",
        descripcion: "Nos especializamos en ingeniería estructural para infraestructura civil, enfocandonos en edificios de mediana y gran altura en Colombia y Panamá. Ofrecemos servicios de Diseño Estructural desagregados, adaptándonos a sus necesidades presupuestales. Trabajamos con sus equipos de arquitectura y construcción garantizando soluciones eficientes y manteniendo el valor en cada proyecto.",
        imagen: estructuras11,
        puntosClave: [
          "Análisis de viabilidad técnica y económica de alternativas estructurales y constructivas.",
          "Ingeniería de valor",
          "Diseño prescriptivo y por desempeño de estructuras de concreto reforzado.",
          "Análisis de alternativas de reforzamiento.",
          "Análisis de vulnerabilidad y repotenciación.",           
          "Patología estructural.",
          "Instrumentación y monitoreo sísmico de estructuras",
          "Supervisión técnica en obra (en Alianza con Obrain)"
        ],
        // quote:"Somos tu Departamento de Consultoría",
        descripcionCorta: "Somos su Departamento de Consultoría.",
        // testimonio: "Gracias al equipo de la empresa, nuestro proyecto se realizó sin contratiempos y con una calidad excepcional.",
        // autorTestimonio: "Ana Gómez, Directora de Proyectos",
        imagesAdvantages: [estructuras12, estructuras13, estructuras14, estructuras15 ],
        ventajaCompetitiva: "Nuestros servicios",
        productos: [
            { id: 1, nombre: "Análisis de viabilidad", descripcion: "Acompañamos al equipo de arquitectura desde las etapas tempranas de estructuración del proyecto para ayudarle a encontrar soluciones eficientes tanto desde el punto de vista estructural como financiero. Evaluamos escenarios constructivos, de excitación sísmica y de sistemas estructurales pero siempre cuantificando de manera precisa los consumos de material para apoyar la toma de decisiones." },
            { id: 2, nombre: "Ingeniería de valor" , descripcion: "Analisamos la Ingeniería existente de su proyecto e identificamos alternativas estructurales que generan oportunidades de mejora en tiempos de ejecución y/o consumo de materiales"},
            { id: 3, nombre: "Diseño en concreto reforzado", descripcion: "Somos expertos en el diseño de edificios de mediana y gran altura, con un gran numero de proyectos en Colombia y Panamá. Usamos métodos prescriptivos (NSR-10, REP-2014) y de diseño por desempeño (PBD). En nuestra metodología de trabajo desagregamos en servicios las diferentes etapas del diseño para estar en sintonía con las necesidades y condiciones presupuestales de los clientes." },
            { id: 4, nombre: "Análisis de reforzamiento", descripcion: "Consideramos múltiples alternativas de reforzamiento para el edificio generando oportunidades y nuevas fuentes de valor que antes parecían inalcanzables. Al permitir a nuestros clientes seleccionar la solución que mejor se adapte a sus necesidades, brindamos un enfoque personalizado y una ventaja competitiva en el mercado de la construcción. En R&DC, transformamos desafíos en oportunidades, impulsados por la tecnología y la excelencia en ingeniería." },
            { id: 5, nombre: "Análisis de vulnerabilidad", descripcion: "Tenemos amplia experiencia en la evaluación de la capacidad estructural de edificaciones históricas, construidas sin normativas modernas y con materiales no tradicionales así como una alta capacidad para proponer soluciones viables para su mejoramiento." },
            { id: 6, nombre: "Patología estructural", descripcion: "Contamos con una amplia experiencia, así como con herramientas numéricas de punta, para identificar las causas de fallas o perdida de funcionalidad en diferentes sistemas estructurales. Nuestro equipo de ingenieros, respaldado por sólidos antecedentes en investigación tiene como procedimiento natural el hacerse preguntas y explicar el funcionamiento de diferentes sistemas." },
            { id: 7, nombre: "Instrumentación sísmica", descripcion: "No solo diseñamos e instalamos de manera completa el sistema de instrumentación sísmica de su edificio sino que además nos encargamos del monitoreo permanente de su estructura con acceso a informes en tiempo real mediante nuestro esquema de computación en la nube. Nuestras herramientas pueden ser consultadas desde su celular inmeditamente despues de la ocurrencia de un evento." },
            { id: 8, nombre: "Supervisión Técnica", descripcion: " En alianza con Obrain, nuestra empresa amiga y especializada en procesos constructivos formamos una potente combinación de supervisión con consultoría. Nos integramos con el equipo de construcción para resolver problemas en obra y garantizar el buen transcurrir del proyecto. Nuestra experiencia en consultoría en geotecnia y estructuras nos permite agilizar la toma de decisiones en campo." },
            { id: 9, nombre: "Despieces inteligentes", descripcion: "En nuestro servicio de despieces generamos metricas que permiten gestionar de manera inteligente el proceso constructivo de la estructura. Nuestro departamento de computación ha diseñado herramientas de planeación y logistica del proceso constructivo de la estructura. Nuestros algorimos de analitica predictiva le permitirán tomar decisiones respaldadas en la complejidad de su despiece así como hacer ajustes con los rendimientos de sus cuadrillas para estimar con mayor precisión los procesos constructivos." }
          ],
          frasePage: 'En R&DC, creemos en la innovación como generador de una ingeniería de calidad. Desde el ADN de nuestros fundadores hemos logrado crear metodologías que aportan valor desde el diseño. ',
          tituloFrase: 'Comprometidos con la innovación'
      },

    computacion: {
      titulo: "Computacion",
      descripcion: "Desarrollamos herramientas computacionales para fortalecer los servicios de consultoría, enfocados en el diseño y la construcción de proyectos de concreto reforzado. Proporcionamos soluciones-a-la-medida a diseñadores y constructores. Nuestras innovaciones contribuyan a la eficiencia dando valor agregado a los proyectos.",
      imagen: computacion11,
      puntosClave: [
        // "Project Designer (ProDes): somos los creadores y distribuidores de Prodes",
        "Análisis de viabilidad.",
        "Ingeniería de valor.",
        "Planos y despieces.",
        "Análisis de reforzamiento."
      ],
        quote:"Diseña de manera inteligente",
        descripcionCorta: "Generamos nuevas fuentes de valor desde el diseño.",
        testimonio: "Gracias al equipo de la empresa, nuestro proyecto se realizó sin contratiempos y con una calidad excepcional.",
        autorTestimonio: "Ana Gómez, Directora de Proyectos",
        imagesAdvantages: [computacion11, computacion12, computacion13, computacion14 ],
        ventajaCompetitiva: "Nuestros servicios",
        productos: [
            // { id: 1, nombre: "ProDes", descripcion: "Nuestro software para despiece automatico de edificios de concreto reforzado. Genere en un solo paso todos los planos de vigas y nervios con altos niveles de detallado." },
            { id: 2, nombre: "Análisis de viabilidad", descripcion: "Utilizamos nuestras herramientas computacionales para apoyar a los desarrolladores de proyectos en la toma de decisiones durante las etapas tempranas de estructuración. Entregamos datos de consumos de material para diferentes escenarios estructurales, constructivos, de excitación sísmica, de particiones, de sistemas de piso, etc." },
            { id: 3, nombre: "Ingeniería de valor", descripcion: "Proponemos diferentes alternativas de reforzamiento para la estructura e identificamos oportunidades de mejora desde el despiece con resultados que pueden impactar los tiempos de ejecución y el consumo de materiales." },
            { id: 4, nombre: "Planos y Despieces", descripcion: "Mediante nuestros desarrollos computiacionales elaboramos sus planos estructurales en tiempo record pero además con precisión y eficiencia. Nuestros servicios especializados de despiece son la solución ídeal para empresas con equipos pequeños de ingeniería y/o de dibujo o que tengan proyectos con plazos ajustados." },
            { id: 5, nombre: "Análisis de reforzamiento", descripcion: "Usamos analitica predictiva conjuntamente con los planos estructurales del proyecto y generamos información que permite gestionar la toma de decisiones durante la construcción de la estructura. Podrá calibrar la velocidad constructiva del proyecto, gestionar la programación de obra y manejar el inventario del proyecto de estructura con decisiones basadas en la información del despiece."}
          ],
        frasePage: 'En R&DC fusionamos el diseño de concreto reforzado con la analítica predictiva. Nuestro equipo de innovación ha implementado herramientas tecnologicas para descubrir nuevas fuentes de valor en cada diseño, alineándose con las necesidades específicas de los constructores y desarrolladores de proyectos.',
        tituloFrase: 'Concreto estructural digitalizado'
    },

    

    geociencias: {
      titulo: "Geociencias",
      descripcion: "Hacemos exploración y análisis geotécnico, iniciando con evaluaciones detalladas del terreno para minimizar riesgos y garantizar la viabilidad de los proyectos. Ajustamos nuestros servicios a las condiciones de sus proyectos e integramos el conocimiento del suelo asegurando soluciones seguras y eficientes.",
      imagen: geociencias11,
      puntosClave: [
        "Viabilidad geomecánica (Capitulo 0)",
        "Análisis de efectos particulares de sitio",
        "Estimación de señales de aceleración para análisis cronologico",
        "Análisis de vibraciones ambientales o inducidas",
        "Análisis de riesgo y estudios de amenaza sísmica",
        "Estudios geomecánicos para obras de infraestructura (civil y minera)",
        "Análisis de estabilidad de taludes",
        "Viabilidad de soluciones de cimentación y contenciones",
        "Estudios de susceptibilidad, amenaza y riesgos por movimientos en masa",
        "Análisis de movimientos de tierra",
        "Construcción de modelos geologicos-geotecnicos",
      ],
      //quote:"Resuelve Problemas mas rapido",
      descripcionCorta: "Anticipamos los riesgos para protejer sus proyectos.",
      testimonio: "Gracias al equipo de la empresa, nuestro proyecto se realizó sin contratiempos y con una calidad excepcional.",
      autorTestimonio: "Ana Gómez, Directora de Proyectos",
      imagesAdvantages: [geociencias12, geociencias12, geociencias13, geociencias14 ],
      ventajaCompetitiva: "Nuestros servicios",
      productos: [
        { id: 1, nombre: "Viabilidad Geomecánica", descripcion: "Evaluamos los lotes para determinar su potencial desde el punto de vista geotecnico. Identificamos diferentes tipos de riesgos y anticipamos la inversión necesaria en contencioanes, cimentaciones, etc. Usamos mediciones de vibraciones ambientales para hacer estimaciones preliminares de las propiedades dinamaicas del sitio y por ende de las excitaciones sísmicas." },
        { id: 2, nombre: "Efectos de sitio", descripcion: "Utilizamos modelos de propagación de ondas conjuntamente con exploración geofísica para determinar el espectro partícular de sitio. En el caso de edificios de periodos largos el uso de una buena caracterización del suelo trae grandes beneficios." },
        { id: 3, nombre: "Determinación de señales de tiempo", descripcion: "Calculamos señales de aceleración del terreno necesarias para proyectos en los que se usen análisis cronologicos." },
        { id: 4, nombre: "Análisis de vibraciones", descripcion: "Utilizamos equipos de última tecnología para determinar vibraciones ambientales o inducidas por maquinaria de diferente indole y evaluar su potencial impacto en la infraestrucura civil." },
        { id: 5, nombre: "Riesgo y amenaza sísmica", descripcion: "Realizamos estudios particulares de riesgo y amenaza sísmica para el diseño de proyectos de infraestructura" },
        { id: 6, nombre: "Estudios Geomecánicos", descripcion: "Determinamos las propiedades geomecánicas del sitio identificando posibles riesgos para su uso en el desplante de una estructura. Hacemos recomendaciones de alternativas de contención y cimentación acordes con las condiciones del lote y los requierimientos de su proyecto" },
        { id: 7, nombre: "Estabilidad de taludes", descripcion: "Estudiamos las condiciones en campo y proponemos medidas para prevenir deslizamientos y garantizar la estabilidad de taludes y terraplenes." },
        // { id: 8, nombre: "Viabilidad de soluciones", descripcion: "En el caso de condiciones de sitio retadoras ayudamos a encontrar oportunidades encontrando alternativas viables y economicamente eficientes." },
        { id: 9, nombre: "Análisis de movimientos de tierra", descripcion: "Integramos la información geotecnica a la planificación del movimiento de tierras para el proyecto generando eficiencias constructivas y economicas" },
  //      { id: 10, nombre: "Análisis de movimientos en masa", descripcion: "Estudios para prevenir deslizamientos y garantizar la estabilidad de taludes y terraplenes." },
  //      { id: 11, nombre: "Modelos geologicos y geotecnicos", descripcion: "Estudios para prevenir deslizamientos y garantizar la estabilidad de taludes y terraplenes." },
      ],
      frasePage: 'Nuestra innovación se enfoca en clarificar las incertidumbres del suelo, vital para proyectos exitosos. Aseguramos soluciones de subestructura precisas y alineadas con las necesidades específicas de cada cliente.',
      tituloFrase: 'Disminuyendo la incertidumbre'
    }
  };
  

