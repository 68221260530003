import React from 'react';
import { Box, Typography, Card, CardContent, Grid, useTheme, useMediaQuery, Container, Button } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import service1 from '../../../assets/service1.png';
import service2 from '../../../assets/service4.png';
import service3 from '../../../assets/computacion3.png';

const services = [
  {
    title: "Ingeniería Estructural",
    description: "Abarcamos problemas generales y especificos asociados con la viabilidad estructural de diferentes obras de infraestructura civil. Somos expertos en análisis y diseño de estructuras de concreto reforzado.",
    image: service1,
    path: '/servicios/disenoEstructural'
  },
  {
    title: "Geociencias",
    description: "Estudiamos el comportamiento del terreno y las intervenciones necesarias para soportar las cargas de las superestructuras. Abordamos el problema de manera integral generando evidentes beneficios en la calidad de los proyectos.",
    image: service2,
    path: '/servicios/geociencias'
  },
  {
    title: "Computación",
    description: "Desarrollamos herramientas computacionales para apoyar nuestras áreas de Ingeniería Civil y poder responder las preguntas que no podemos responder con las herramientas disponibles en el mercado.",
    image: service3,
    path: '/servicios/computacion'
  }
  
];

function ServicesSection() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleServiceClick = (path) => {
    navigate(path);
  };

  // Función para generar las tarjetas de servicios con el efecto hover deseado
  const renderServiceCard = (service) => (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        sx={{
          height: 400, // Increased height for better vertical layout
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end', // Adjust text to appear at the bottom of the image
          alignItems: 'center',
          textAlign: 'center',
          position: 'relative',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(${service.image})`,
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.03)', // Slight zoom effect on hover
            '& .serviceContent': {
              opacity: 1,
              visibility: 'visible',
            }
          },
          overflow: 'hidden',
        }}
      >
        <Typography
          gutterBottom
          variant={isMobile ? "h6" : "h5"}
          component="div"
          sx={{
            color: '#fff',
            fontWeight: 'bold', // Make title text bolder for readability
            mt: 2,
            zIndex: 2,
            transition: 'color 0.3s',
            '&:hover': {
              color: theme.palette.primary.main, // Change color on hover
            }
          }}
        >
          {service.title}
        </Typography>
        <Box
          className="serviceContent"
          sx={{
            position: 'absolute',
            bottom: 0, // Adjust to bottom for slide-up effect
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0,
            visibility: 'hidden',
            transition: 'opacity 0.5s, visibility 0.5s',
            zIndex: 1,
          }}
        >
          <CardContent sx={{ p: 3, textAlign: 'left', color: '#fff' }}>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {service.description}
            </Typography>
            <Button variant="contained" size="large" onClick={() => handleServiceClick(service.path)}>Conoce mas</Button>
          </CardContent>
        </Box>
      </Card>
    </Grid>
  );

  return (
    <Box sx={{ bgcolor: '#E3E3E3', py: 8 }}>
      <Container maxWidth="xl" sx={{ marginX: 'auto', overflow: 'hidden', py: 8 }}> 
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            fontFamily: 'Roboto',
            textDecoration: 'underline',
            textDecorationColor: '#961A1D',
            textDecorationThickness: '2px',
            textUnderlineOffset: '3px',
          }}
        >
          Áreas Estratégicas
        </Typography>
        <Grid container spacing={isMobile ? 2 : 4} justifyContent="center">
          {services.map(service => renderServiceCard(service))}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          {/* <Button
            component={RouterLink} // Le indicamos que el componente Button actúe como un Link
            to="/proyectos" // Ruta a la que debe dirigir
            variant="contained"
            color="primary"
            size="large"
            sx={{
              marginTop:'12px',
              fontFamily: 'Montserrat',
              fontWeight: 700,
              backgroundColor: '#961A1D', // Tu color personalizado
              '&:hover': {
                backgroundColor: '#0056b3', // El color que deseas al hacer hover, he puesto un azul como ejemplo
              },
            }}
          >
            Explora nuestros proyectos
          </Button> */}
        </Box>
      </Container> 
    </Box>
  );
}

export default ServicesSection;

